@import (less) "mixins.less";

ul#menu-mobile-navigation{
    width:100%;
    background:@red;
    padding:0;
    margin:0;
    display:none;
    li{
        display:block;
        .centered();
        a{
            display:block;
            width:100%;
            padding:15px;
            .poppins(16px);
            color:#fff;
            .uppercase();
            font-weight: 600;
            border-bottom:1px solid rgba(255,255,255,0.2);
            &:hover{
                }
            }
        &:last-child{
        	a{border:none;}
        	}
        }
    }
    
a#menu-toggle{
    color:#fff;
    font-size:24px;
    position:absolute;
    top:10px;
    right:10px;
    display:none;
    z-index:500;
    &.active{
        }
    }


@media only screen 
and (max-width : 900px) {
    ul#menu-main-navigation-1{
        li{
            a{font-size:12px;}
            }
        }
    }
    
@media only screen 
and (max-width : 800px) {
   
   
    }
 
@media only screen 
and (max-width : 768px) {
	a#menu-toggle{
       display:inline-block;
       }
    #menu-main-navigation{display:none;}
    #main-header{
    	position: relative;
    	background:@blueDark;
    	height: auto;
    	padding:20px;
    	.centered();
    	.logo-svg, img.logo{
    		.inline-block();
    		width: 80%!important;
    		max-width:300px!important;
    		}
    	#top-bar{
    		display: none!important;
    		}
    	}
    #banner{
    	&.homepage{
    		.scaler();
    		background:url(images/video-poster.jpg) no-repeat center center;
    		padding:30px 0 30px 0;
    		height:auto;
    		video#banner-video{display: none;}
    		.banner-content{
    			transform:translateY(0);
    			top:0;
    			padding:10px;
    			font-size:24px;
    			h1{
    				line-height:1.2;
    				font-size:45px;
    				}
    			}
    		}
    	&.subpage{
    		    padding: 40px 0 40px 0;
    		}
    	}
    .section{
    	h5.section-head{
    		font-size:36px;
    		}
    	}
    #services-section{
    	.central{
    		padding:30px 10px 30px 10px!important;
    		}
    	#service-item-wrap{
    		margin-top:10px;
    		>.service-item{
    			border:none!important;
    			border-top:2px solid @beigeLight!important;
    			min-height:0;
    			height:auto;
    			padding:30px;
    			}
    		}
    	}
    #providers-section{
    	#provider-section-content{
    		padding:20px;
    		width:100%;
    		.centered()!important;
    		>div{
    			.centered();
    			}
    		.text{
    			display: none;
    			}
    		}
    	}
    #latest-section{
    	padding:30px 0 30px 0;
    	.latest-rotator-item{
    		padding-left:40px;
    		padding-right:40px;
    		.blog-image{
    			height:200px;
    			}
    		.blog-content{
    			h5.blogtitle{font-size: 26px!important;}
    			}
    		}
    	}
    .top-title{
    	width:100%;
    	font-size: 32px!important;
    	padding:20px;
    	&:after{display:none;}
    	.icon{
    		display: none;
    		}
    	}
	#main-content{
		padding-right:10px!important;
		padding-left:10px!important;
		border:none;
		h2,h3,h4{line-height: 1.3;}
		h3{
			font-size:26px;
			}
		}
	#sidebar{
		padding-left:10px!important;
		padding-right:10px!important;
		}
	.toggler{
		.trigger{
			padding: 25px 40px 25px 25px;
			.icon{display: none;}
			}
		}
	.location-item{
		.map{margin-bottom: 30px;}
		.details{
			.centered();
			padding:20px 0 20px 0;
			h6{
				font-size: 24px;
				}
			.detail{
				.centered();
				padding:30px 0 0 0;
				i{
					top:0;
					left:50%;
					transform:translateX(-50%);
					}
				}
			}
		}
	.provider-item{
		margin:20px 0 20px 0;
		}
	.service-sub-item{
		margin:10px 0 10px 0;
		}
    .mobile-center{.centered();}
    .mobile-hide{display:none!important;}
    .gform_wrapper{
    	ul.gform_fields{
    		>li.gfield.gf_left_half, li.gfield.gf_right_half{width:100%!important;margin-right:0!important;margin-left:0!important;}
    		li .ginput_container > i{
    			top:23px;
    			}
    		}
    	}
    }
   
@media only screen 
and (max-width : 480px) {



}